import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IntegraionStatusService } from "./integraion-status.service";

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  //baseUrl = 'https://stage.api.jasiricap.com';
  baseUrl = environment.baseUrl;
  integrationStatus$!: any
  constructor(
    private http: HttpClient,
    private integraionStatusService: IntegraionStatusService
  ){
    this.integraionStatusService.integrationStatus$.subscribe(
      (IntegraionStatus) => {this.integrationStatus$ = IntegraionStatus;}
    );
  }

  getUsersByPage(page: any, pagesize: any) {
    return this.http.get(this.baseUrl + '/api/v1/customer/list?p=' + page + '&page_size=' + pagesize);
  }

  getUsers(page: any, pagesize: any) {
    //return this.http.get(this.baseUrl+'/api/v1/customer/list?page_size='+pagesize);
    return this.http.get(this.baseUrl + '/api/v1/customer/list?p=' + page + '&page_size=' + pagesize);
  }

  getUserById(id: Number) {
    return this.http.get(this.baseUrl + '/api/v1/customer/detail/' + id);
  }

  customerUpdate(id: Number, service:number | null, body: any) {

    return ((this.integrationStatus$ === 'true' || this.integrationStatus$ === true) && service !== null) ?
      this.http.patch(this.baseUrl + "/api/v1/customer/tp_update/" + id,body)
      :
      this.http.post(this.baseUrl + "/api/v1/customer/update/" + id, body)
    
  }

  getToken() {
    return localStorage.getItem('access');
  }

  deleteCustomer(id: any, invoiceService:any) {
    let url = invoiceService != null ? '/api/v1/customer/inactive_customer/' : '/api/v1/customer/detail/'
    return this.http.delete(this.baseUrl + url + id);
  }

  customerCreate(user: any) {
    return (this.integrationStatus$ === 'true' || this.integrationStatus$ === true) ?
      this.http.post(this.baseUrl + "/api/v1/customer/tp_create", user)
    :
      this.http.post(this.baseUrl + "/api/v1/customer/create", user)
  }

  /* below for ngx-pagination */

  getAll(params: any): Observable<object> {
    return this.http.get<any>(this.baseUrl + '/api/v1/customer/list', { params });
  }

  getIndustry() {
    return this.http.get(this.baseUrl + '/api/v1/account/static-content');
  }

  getAllUsersForExport(params: any) {
    return this.http.get(this.baseUrl + '/api/v1/customer/csv', { params });
  }

}
