import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  //baseUrl = 'https://stage.api.jasiricap.com';
  baseUrl = environment.baseUrl
  constructor(private http: HttpClient) { }

  setPaymentMethod(methods: any) {
    return this.http.post<any>(this.baseUrl + '/api/v1/invoice/payment-method', methods);
  }

  getAccountDetails() {
    return this.http.get(this.baseUrl + '/api/v1/account/get-details');
  }

  createPaymentReminder(data: any) {
    return this.http.post(this.baseUrl + '/api/v1/invoice/payment-reminder', data);
  }

  updatePaymentReminder(data: any, reminderId: any) {
    return this.http.put(this.baseUrl + '/api/v1/invoice/payment-reminder/' + reminderId, data);
  }

  deletePaymentReminder(reminderId: any) {
    return this.http.delete(this.baseUrl + '/api/v1/invoice/payment-reminder/' + reminderId);
  }

  setSchedulePayment(invoiceId: any) {
    return this.http.get(this.baseUrl + '/api/v1/invoice/schedule-payment/' + invoiceId);
  }

  updateRolePermissions(roles: any) {
    return this.http.post(this.baseUrl + '/api/v1/invoice/update-role-permissions', roles);
  }

  getDashboardData(startDate: any) {
    return this.http.get(this.baseUrl + '/api/v1/account/dashboard?date=' + startDate);
    //return this.http.get(`${this.baseUrl}/api/v1/account/dashboard?start_date=${startDate}&end_date=${endDate}`);
  }

  getSessionToken() {
    return this.http.get(this.baseUrl + '/api/v1/account/connect-integration/');
  }

  SetMpesa(credentials : any){
    return this.http.post(this.baseUrl + '/api/v1/account/mpesa-credentials/', credentials);
  }

  mPesaCredentials(user: any) {
    return this.http.get(this.baseUrl + '/api/v1/account/mpesa-credentials' + user)
  }

  mPesaCallback(payload: any) {
    return this.http.post(this.baseUrl + '/api/v1/account/mpesa_callback/', payload)
  }

  checkMpesaCred(userId : string){
    return this.http.get(this.baseUrl + `/api/v1/account/mpesa-credentials/?user=${userId}`)
  }

  toggleMpesaStatus(action :string){
    return this.http.post(this.baseUrl +'/api/v1/account/mpesa_activate/',{action : action})
  }

  editMpesaCreds(payload : any,id : number) {
    return this.http.put(this.baseUrl + `/api/v1/account/mpesa-credentials/${id}/`,payload)
  }
}
