import { NgModule } from '@angular/core';
import { RouterModule, Routes  } from '@angular/router';
import { LoginGuard } from './guards/login.guard';
import { LoginComponent } from './login/login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForgetPasswordComponent } from './login/forget-password/forget-password.component'; 
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { InvoicePaymentComponent } from './admin/invoice/invoice-payment/invoice-payment.component';

const routes: Routes = [
  {path:'',redirectTo:'/login',pathMatch:'full'},
  {path:'login',component:LoginComponent},
  {path:'forget-password',component:ForgetPasswordComponent},
  {path:'reset-password',component:ResetPasswordComponent},
  { path: 'invoice/invoice-payment/:id', component: InvoicePaymentComponent },
  {path:'admin' , canActivate:[LoginGuard] , loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
  /*{path:'dashboard' , loadChildren: () => import('./main-dashboard/main-dashboard.module').then(m => m.MainDashboardModule)},*/
  {path:'super-admin' , loadChildren: () => import('./super-admin/super-admin.module').then(m => m.SuperAdminModule)},
  {path:'**',component:PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
