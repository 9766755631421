<div class="container-fluid">
  <div class="row">
  <div class="login-container d-flex flex-direction-row">
    <div class="l-parent" style="display:flex; align-items:center; width:40%;height: 100vh;
    padding: 50px;
    background: linear-gradient(180deg, #072b69, #0D59C7);
    text-align: center;">
      <div class="left-login">
      <img src="./assets/images/Jasiri Main logo.png"/>
      <!--login carsouel-->
      <!-- Carousel -->
<div id="demo" class="carousel slide" data-bs-ride="carousel">

  <!-- Indicators/dots -->
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
  </div>

  <!-- The slideshow/carousel -->
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="./assets/images/logincarsouel.png" alt="Los Angeles" class="d-block w-80">
    </div>
    <div class="carousel-item">
      <img src="./assets/images/logincarsouel.png" alt="Chicago" class="d-block w-80">
    </div>
    <div class="carousel-item">
      <img src="./assets/images/logincarsouel.png" alt="New York" class="d-block w-80">
    </div>
  </div>

  <!-- Left and right controls/icons -->
  <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
    <span class="carousel-control-next-icon"></span>
  </button>
</div>

<!--below carsouel content-->
<div class="carsouel-content">
    <h4 class="myh4">WELCOME TO</h4>
    <h2>Cash Flow Management</h2>
    <a  href="#loginscreen" class="loginbtn" > Login to access dashboard </a>
</div>
<div>
 </div>
 </div>
 </div>
     
     <div class="r-parent" style="display:flex; align-items:center; width:60%;background:#F3F5F9; height:100vh;">
      <div class="right-login" id="loginscreen">
      <h3>Login</h3>
      
      <form [formGroup]="loginForm" (ngSubmit)="loginFormData()">
          <div class="mb-3 mt-3 mb40">
            <label for="email" class="form-label">Email:</label>
               <div *ngIf="loginForm.controls['email'].invalid && loginForm.controls['email'].touched" style="display:inline;margin:10px;">
                  <small class="text-danger" *ngIf="loginForm.controls['email'].hasError('required') ">
                      Email required.
                  </small>
                  <small class="text-danger" *ngIf="loginForm.controls['email'].hasError('pattern') ">
                      Please Enter Valid Email Address
                  </small>
                  <small class="text-danger" *ngIf="loginForm.hasError('maxlength', 'email')">
                    Email should be less than 25 characters .
                  </small>
                </div>
            <input [class.is-invalid]="loginForm.controls['email'].invalid && loginForm.controls['email'].touched" type="email" formControlName="email" class="form-control" placeholder="Enter your  email">
             
          </div>
          <div class="mb-3 mt-3 mb40" style="position: relative;">
            <label for="pwd" class="form-label ">Password:</label>

            <div *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched" style="display:inline;margin:10px;">
              <small class="text-danger" *ngIf="loginForm.controls['password'].hasError('required') ">
                  Password required.
              </small>
            </div>
           
            <span class="showpwd" (click)="showPassword()" style="cursor:pointer;" >
              
              <img *ngIf="pass_type=='text'" style="width:18px; height:16px;" src="./assets/images/crosseye.png"/>
              <img *ngIf="pass_type=='password'" style="width:18px; height:16px;" src="./assets/images/Show.png"/>
   
              </span>
            <input [class.is-invalid]="loginForm.controls['password'].invalid && loginForm.controls['password'].touched" type="{{pass_type}}" formControlName="password" class="form-control" placeholder="Password">
          </div>
          <div class="form-check mb-3 mt-3 d-flex justify-content-between remeb">
            <label class="form-check-label rememberme">

              <div  *ngIf="remember;else other_content">
                <input class="form-check-input" style="background:url('../../../assets/images/checksuccess.png');background-size:cover;"  type="checkbox" name="remember" checked (click)="rememberMe()"
                > Remember me
              </div>

               <ng-template #other_content>
                <input class="form-check-input" type="checkbox" name="remember" checked (click)="rememberMe()"
                > Remember me
               </ng-template>

            </label>
            <label class="form-check-label">
             <a class="forgetpwd" routerLink="/forget-password">Forgot Password?</a>
         
            </label>
          </div>
          <button type="submit" [disabled]="loginForm.invalid" class="btn btn-primary submitbtn">
             Login
             <img *ngIf="isLoad"  src="./assets/images/load.gif" style="width:20px;" />
            </button>
        </form>
      </div>
      </div>
    </div>
  </div>
</div>


