import { NgModule } from '@angular/core';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule ,HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { TokenInterceptorService } from './interceptors/token-interceptor.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginGuard } from './guards/login.guard';
import { LoginComponent } from './login/login/login.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './login/forget-password/forget-password.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfViewerModule  }  from  'ng2-pdf-viewer';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    PdfViewerModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    CKEditorModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({timeOut: 5000,
      preventDuplicates: true,
      enableHtml: true,
      closeButton: true,
      progressBar: true}),
  ],
  providers: [LoginGuard , {
        provide:HTTP_INTERCEPTORS,
        useClass:TokenInterceptorService,
        multi:true
      }],
  bootstrap: [AppComponent]
})
export class AppModule { }
