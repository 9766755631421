import { Component, OnInit } from '@angular/core';
import{FormBuilder,Validators,FormControl,FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/services/login.service';

 
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
 
export class ResetPasswordComponent implements OnInit {

  pass_type1:string='password';
  pass_type2:string='password';

  uuid:string='';
  time:string='';

  isLoad:boolean=false;

  resetpasswordForm:FormGroup;
  submitted:boolean =false;

/*This is foe eye button functionality*/ 
showPassword1:boolean=true;
showPassword2:boolean=true;
showIcon1:boolean=false;
showIcon2:boolean=false;
switchEyeIcon1(){
  this.showPassword1=!this.showPassword1;
  this.showIcon1=!this.showIcon1;
}
switchEyeIcon2(){
  this.showPassword2=!this.showPassword2;
  this.showIcon2=!this.showIcon2;
}



  constructor(public fb:FormBuilder,
              public loginservice:LoginService,
              private route:ActivatedRoute,
              private router:Router,
              private toastr: ToastrService)
  {
    this.resetpasswordForm = this.fb.group({
      password    : ['',[Validators.required,Validators.pattern(/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/) ] ],
      confirmpassword : ['',[Validators.required]]
    },{
      validators:this.MustMatch('password','confirmpassword')
    })
  }

  MustMatch(controlName:string,matchingControlName:string)
  {
    return (formGroup:FormGroup)=>{
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if(matchingControl.errors && !matchingControl.errors['MustMatch'])
      {
        return ;
      }
      if(control.value !== matchingControl.value)
      {
        matchingControl.setErrors({MustMatch:true});
      }else{
        matchingControl.setErrors(null);
      }
    }
  }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params:any) => {
      this.uuid = params.uuid;
      this.time = params.time;
      console.log(params.uuid);
     }
   );

  }

  get f()
  {
    return this.resetpasswordForm.controls;
  }  
  

  

  resetpasswordData()
 {
  this.submitted = true;
  if(this.resetpasswordForm.invalid) { return ;}
   this.isLoad=true;
   let sendData = {
                   uuid:this.uuid,
                   time:this.time,
                   password:this.resetpasswordForm.value.password
                  };
    
     this.loginservice.resetPassword(sendData).subscribe((res:any)=>{
       this.toastr.success(res.message);
       this.isLoad=false;
       this.router.navigate(['/login']);
     },
     (err:any)=>{
       this.toastr.error(err.error.message);
       this.isLoad=false;
       this.router.navigate(['/forget-password']);
     })

 }


 

 


}
