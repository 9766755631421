import { Injectable , Injector} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest , HttpResponse} from '@angular/common/http';
import { LoginService } from '../services/login.service';
import { Observable } from 'rxjs';
import { catchError,tap} from 'rxjs/operators';
import { Route, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector:Injector,private router:Router) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    

    let loginservice =this.injector.get(LoginService)  
   

    if(!loginservice.getToken())
    {
      return next.handle(req)

    }
    else
    {
      
      let tokenizeReq = req.clone({
        setHeaders:{
          authorization: `Bearer ${loginservice.getToken()}`
        }
      });
      return next.handle(tokenizeReq).pipe(
                                            tap(event => {
                                              if (event instanceof HttpResponse) {
                                                
                                                //console.log(" all looks good");
                                                //console.log(event.status);
                                              }
                                            }, error => {

                                              if(error.status == 401)
                                              {
                                                localStorage.removeItem('userdetails');
                                                localStorage.removeItem('access');
                                                localStorage.removeItem('refresh');
                                                this.router.navigate(['/login']);
                                              }
                                                // console.log("----response----");
                                                // console.error("status code:");
                                                // console.error(error.status);
                                                // console.error(error.message);
                                                // console.log("--- end of response---");

                                            })
                                          );

    }
    

   
  }


}
