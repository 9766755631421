import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IntegraionStatusService } from './integraion-status.service';


@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  //baseUrl = 'https://stage.api.jasiricap.com';
  baseUrl = environment.baseUrl
  integrationStatus$!: any
  constructor(
    private http: HttpClient,
    private integraionStatusService: IntegraionStatusService
  ) {
    this.integraionStatusService.integrationStatus$.subscribe(
      (IntegraionStatus) => {this.integrationStatus$ = IntegraionStatus;}
    );
  }

  invoiceId: any;

  /* below for ngx-pagination */
  getAllInvoice(params: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/api/v1/invoice/list', { params });
  }
  /*delete from db*/
  deletinvocefromDB(id: any) {
    return this.http.delete(this.baseUrl + '/api/v1/invoice/delete/' + id);
  }
  /*end here*/
  createInvoice(data: any) {
    return this.http.post(this.baseUrl + '/api/v1/invoice/create', data);
  }

  updateInvoice(data: any, id: any) {
    return this.http.put(this.baseUrl + '/api/v1/invoice/update/' + id, data);
  }

  DeleteInvoiceRow(id: any) {
    return this.http.delete(this.baseUrl + '/api/v1/invoice/delete/' + id);
  }

  getInvoiceById(id: any) {
    return this.http.get(this.baseUrl + '/api/v1/invoice/details/' + id);
  }


  deleteAttachment(id: number) {
    return this.http.delete(this.baseUrl + '/api/v1/invoice/attachment/delete/' + id);
  }

  sendInvoiceEmail(userid: any, data: any) {
    return this.http.post(this.baseUrl + '/api/v1/invoice/send-email-invoice/' + userid, data);
  }

  sendInvoiceWhatsapp(invoiceid: any, data: any) {
    return this.http.post(this.baseUrl + '/api/v1/invoice/send-whats-invoice/' + invoiceid, data);
  }

  Proceedorpayment(invoicesuccessid: any, payment_obj: any, invoiceService: number | null) {
    return ((this.integrationStatus$ === 'true' || this.integrationStatus$ === true) && invoiceService !== null) ?
      this.http.post(this.baseUrl + '/api/v1/invoice/tp-record-payment/' + invoicesuccessid, payment_obj)
    :
      this.http.post(this.baseUrl + '/api/v1/invoice/record-payment/' + invoicesuccessid, payment_obj);

  }

  getAllInvoiceForExport(params: any) {
    return this.http.get(this.baseUrl + '/api/v1/invoice/csv', { params });

  }

  sendReminder(invoice_id: any, data: any) {
    return this.http.post(this.baseUrl + '/api/v1/invoice/send-reminder/' + invoice_id, data);
  }

  createNewInvoice(data: any) {
    return this.http.post(this.baseUrl + '/api/v1/invoice/create-invoice', data);
  }

  deleteInvoiceLineItem(id: any) {
    return this.http.get(this.baseUrl + '/api/v1/invoice/invoice-item/delete/' + id);
  }

  AddInvoiceLineItem(invoiceId: any, object: any) {
    return this.http.post(this.baseUrl + '/api/v1/invoice/add-invoice-items/' + invoiceId, object);
  }

  UpdateInvoiceLineItem(invoiceId: any, object: any) {
    return this.http.put(this.baseUrl + '/api/v1/invoice/add-invoice-items/' + invoiceId, object);
  }

  updateNewInvoice(invoice_id: any, data: any) {
    return this.http.post(this.baseUrl + '/api/v1/invoice/update-create-invoice/' + invoice_id, data);
  }

  createInvoiceTemplate(data: any) {
    return this.http.post(this.baseUrl + '/api/v1/invoice/templates', data);
  }

  getInvoiceTemplate() {
    return this.http.get(this.baseUrl + '/api/v1/invoice/get-templates');
  }

  mPesaPayment(payload: any) {
    return this.http.post(this.baseUrl + '/api/v1/account/mpesa/', payload)
  }
  
  getInvoiceForPayment(id : string){
    return this.http.get(this.baseUrl + `/api/v1/invoice/invoice_list/${id}`)
  }
}

