import { Component, OnInit } from '@angular/core';
import { FormBuilder , FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
 

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  isLoad:boolean=false;

  forgetPasswordForm:FormGroup;
  submitted:boolean =false;

  constructor(public loginservice:LoginService,
              public fb:FormBuilder,
              private router:Router,
              private titleservice:Title,
              private toastr: ToastrService)
    {
      this.forgetPasswordForm = this.fb.group({
        email:['',[Validators.required,Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]]
      }) 
    }

  get f()
  {
    return this.forgetPasswordForm.controls;
  }        


  ngOnInit(): void {
 
     this.titleservice.setTitle('forget-password');

  }

  forgetpasswordData()
  {
    
    this.submitted = true;
    if(this.forgetPasswordForm.invalid) { return ;}
    
    this.isLoad=true;
    this.loginservice.forgetPassword(this.forgetPasswordForm.value).subscribe((res:any)=>{
      
      this.toastr.success(res.message);
      setTimeout(() => {
        this.router.navigate(['login']);
      }, 2000);
      this.isLoad=false;

    },
    (err:any)=>{
      this.isLoad=false;
      console.log(err.error.detail[0]);
      this.toastr.error(err.error.detail[0]);
    })
  }

}
