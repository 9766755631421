<div class="forget-password">
    <div class="password-inner"> 
       <h2>Forgot Your Password?</h2>
       <p>Please enter your email to sign in</p>
       <!-- <div class="forgetdiv"></div> -->
       <form [formGroup]="forgetPasswordForm" (ngSubmit)="forgetpasswordData()">
         <label class="form-label">Email</label>
         <i style="color:red">*</i>
          <input type="email" class="form-control"  formControlName="email" placeholder="Enter Email">
           
          <div *ngIf="submitted && f['email'].errors">
            <small class="text-danger" *ngIf="forgetPasswordForm.hasError('required', 'email')">
               Email required.
              </small>
   
              <small class="text-danger" *ngIf="forgetPasswordForm.hasError('pattern', 'email')">
               Invalid Email.
              </small>
          </div>
          
          <button type="submit" [disabled]="isLoad" class="btn btn-primary form-control reqreset">
             Request Password Reset
             <img *ngIf="isLoad"  src="./assets/images/load.gif" style="width:20px;" />  
          </button>
          <a class="btl" routerLink="/login" >Back to Sign In</a>
       </form>
    </div>
 </div>

