<div class="reset-password">
<!--
    <div class="reset-inner">
        <h2>Reset Password?</h2>
        <p>The password should have at least 6 characters long</p>
        <div class="forgetdiv"></div>

       

        <form [formGroup]="resetpasswordForm" (ngSubmit)="resetpasswordData()" >
           <div class="reldivpwd">
            <span class="showpwd"  style="cursor:pointer;" >
              
              <img  style="width:18px; height:16px;" src="./assets/images/crosseye.png"/>
                <img  style="width:18px; height:16px;" src="./assets/images/Show.png"/>
            
            </span>

                <input type="{{pass_type1}}" formControlName="password" class="form-control" placeholder="New Password"   >

               
            </div>-->

  <div class="reset-inner">
     <h2>Reset Password?</h2>
     <p>The Password should contain at least 8 characters and must contain:</p>
     <ul>
       <li>one uppercase</li>
       <li>one lowercase</li>
       <li>one number</li>
       <li>one special character</li>
     </ul>
     <form [formGroup]="resetpasswordForm" (ngSubmit)="resetpasswordData()" >
     <div class="reldivpwd">
        <span class="showpwd"  style="cursor:pointer;" >
          <img (click)="switchEyeIcon1()"   style="width:18px; height:16px;" [src]="showIcon1?'./assets/images/crosseye.png':'./assets/images/Show.png'"/> 
        </span>
        <label class="form-label">Password</label>
        <i style="color:red">*</i>
        <input [type]="showPassword1?'password':'text'" formControlName="password" [class.is-invalid]="resetpasswordForm.controls['password'].invalid && resetpasswordForm.controls['password'].touched" class="form-control" placeholder="New Password"   >

        
        <div *ngIf="submitted && f['password'].errors">
          <small class="text-danger" *ngIf="resetpasswordForm.hasError('required', 'password')">
            Enter new password.
          </small>
          <small class="text-danger" *ngIf="resetpasswordForm.hasError('pattern', 'password')">
            Password cannot be less than 8 characters and must contain one uppercase, one lowercase, one number, and one special character.
          </small>
         </div>

      </div>
     <div class="reldivpwd mt-4" >
        <span class="showpwd"  style="cursor:pointer;" >
          <img (click)="switchEyeIcon2()"   style="width:18px; height:16px;" [src]="showIcon2?'./assets/images/crosseye.png':'./assets/images/Show.png'"/> 
         
        </span>
        <label class="form-label">Confirm Password</label>
        <i style="color:red">*</i>
        <input [type]="showPassword2?'password':'text'" formControlName="confirmpassword" [class.is-invalid]="resetpasswordForm.controls['confirmpassword'].invalid && resetpasswordForm.controls['confirmpassword'].touched" class="form-control" placeholder="Confirm Password"   >
        
        <div *ngIf="submitted && f['confirmpassword'].errors">
          <small class="text-danger" *ngIf="resetpasswordForm.hasError('required', 'confirmpassword')">
           confirm password is required !.
          </small>
          <small class="text-danger" *ngIf="f['confirmpassword'].errors['MustMatch']" >
            confirm password did't match !.
           </small>
        </div>

      </div>
     <button type="submit" [disabled]="isLoad" class="btn btn-primary mt-4"> 
        Reset password
        <img *ngIf="isLoad"  src="./assets/images/load.gif" style="width:20px;" /> 
     </button>
     </form>
  </div>
</div>