import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CustomerService } from './services/customer.service';
import { Spinkit } from 'ng-http-loader';
import { IdleService } from './services/idle.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{
  spinnerStyle = Spinkit;
  title = 'invocing_platform';
  router: any;

  
  
  constructor(private titleservice:Title,
              private customerservice:CustomerService,
              private idleService : IdleService
             )
  {

    this.idleService.idle()

  }

  

  ngOnInit(): void {
    
    //this.socketioservice.initSocket();

    

  }

}
