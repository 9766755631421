import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";

@Injectable({
    providedIn: 'root'
})
export class IdleService {

    currentPath!: String;

    idleState = 'Not started.';
    timedOut = false;
    lastPing !: Date
    private IDLE_TIMEOUT = 3600;
    private WARNING_DURATION = 20; 

    constructor(
        private Idle: Idle,
        private KeepAlive: Keepalive,
        private router: Router
    ) {
    
    }

    idle(){
        this.Idle.setIdle(this.WARNING_DURATION);
        this.Idle.setTimeout(this.IDLE_TIMEOUT);
        this.Idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.Idle.onIdleEnd.subscribe(() => {
            this.idleState = 'No longer idle.';
        });

        this.Idle.onTimeout.subscribe(() => {
            this.idleState = 'Timed out!';
            this.timedOut = true;
            this.logOut()
        });

        this.Idle.onIdleStart.subscribe(() => {
            this.idleState = 'You\'ve gone idle!';
        });

        this.Idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = 'You will time out in ' + countdown + ' seconds!';
        });

        this.KeepAlive.interval(15);
        this.KeepAlive.onPing.subscribe(() => {
            this.lastPing = new Date();
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentPath = event.urlAfterRedirects;
                if (this.currentPath.search(/authentication\/login/gi) === -1) {
                    this.Idle.watch();
                } else {
                    this.Idle.stop();
                }
            }
        });
    }
    reset() {
        this.Idle.watch();
        this.idleState = 'Started.';
        this.timedOut = false;
    }

    logOut(){
        localStorage.removeItem('userdetails');
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        this.router.navigate(['/login']);
    }
}